* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.page-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 576px) {
  .page-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .page-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .page-container {
    max-width: 1200px;
  }
}

@-webkit-keyframes fadeInGreeting {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInGreeting {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate {
  -webkit-animation: fadeInGreeting 0.5s ease;
          animation: fadeInGreeting 0.5s ease;
}

.text-center {
  text-align: center;
}

header.main-header {
  padding-top: 30px;
}

header.main-header img.logo {
  max-width: 50px;
}

main.career {
  padding-bottom: 100px;
}

main.career .intro {
  margin: 100px 0 70px 0;
}

main.career .intro .greeting {
  font-size: 100px;
  font-weight: 900;
  display: block;
}

main.career .intro .greeting-thnx {
  font-size: 70px;
  font-weight: 900;
  display: block;
}

main.career .intro p {
  max-width: 700px;
  margin: 0 auto;
  line-height: 24px;
  margin-top: 30px;
}

main.career .intro p span {
  font-weight: 700;
}

main.career .apply-form {
  max-width: 700px;
  margin: 0 auto;
}

main.career .apply-form .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 20px;
}

main.career .apply-form.form-has-errors .grid {
  gap: 30px 20px;
}

.input-group {
  position: relative;
}

.input-group span.error {
  font-size: 12px;
  display: block;
  position: absolute;
  background: white;
  top: -15px;
  color:red
}

.input-group input, .input-group textarea {
  width: 100%;
  padding: 12px 17px;
  font-size: 14px;
  color: black;
  border: 1px solid black;
  font-family: 'Montserrat', sans-serif;
  resize: none;
}

.input-group ::-webkit-input-placeholder {
  color: black;
}

.input-group :-ms-input-placeholder {
  color: black;
}

.input-group ::-ms-input-placeholder {
  color: black;
}

.input-group ::placeholder {
  color: black;
}

.input-group--full {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
}

.apply-btn {
  width: 100%;
  background-color: black;
  color: white;
  font-weight: 700;
  cursor: pointer;
  padding: 12px;
  font-size: 16px;
  position: relative;
  z-index: 1;
  border: 1px solid black;
}

.apply-btn::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  left: auto;
  background: white;
  z-index: -1;
  -webkit-transition: .3s;
  transition: .3s;
}

.apply-btn:hover {
  color: black;
}

.apply-btn:hover::before {
  width: 100%;
  left: 0;
}

span.required-fields {
  font-size: 12px;
  display: block;
}

.divider {
  width: 100%;
  height: 0.5px;
  display: block;
  background: black;
  margin: 20px 0;
}

footer {
  padding: 40px 0;
}

footer .logo {
  opacity: 1;
}

footer .logo img {
  max-width: 200px;
}

footer .widgets {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

footer .widgets .widget h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

footer .widgets .widget a {
  display: block;
  color: black;
  text-decoration: none;
}

footer .widgets .widget p, footer .widgets .widget a {
  opacity: 0.6;
  font-size: 14px;
}

footer p.copyright {
  opacity: 0.6;
  font-size: 14px;
}

@media (max-width: 767px) {
  footer .widgets {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  footer .widget--logo {
    -ms-grid-column: 1;
        grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: 576px) {
  main.career .apply-form .grid {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .input-group--full {
    grid-column-end: 2;
  }
  footer .widgets {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  footer .widget--logo {
    -ms-grid-column: 1;
        grid-column-start: 1;
    grid-column-end: 2;
  }
}
/*# sourceMappingURL=style.css.map */

@media (max-width: 767px) {
  main.career .intro .greeting {
    font-size: 70px;
  }
}

@media (max-width: 767px) {
  main.career .intro .greeting-thnx {
    font-size: 40px;
  }
}

.apply-btn:disabled {
  cursor: not-allowed;
  opacity: 0.8
}
